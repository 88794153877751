import React, { useContext, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { Formik, Form } from "formik";

import AddressForm from "./Forms/AddressForm";
import PaymentForm from "./Forms/PaymentForm";
import ReviewOrder from "./ReviewOrder";
import CheckoutSuccess from "./CheckoutSuccess";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";


import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import useStyles from "./styles";
import QRCode from "react-qr-code";


export default function CheckoutPage(props) {
  const steps = ["Dados", "Personalizar", "Revisar"];
  const { formId, formField } = checkoutFormModel;



  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [datePayment, setDatePayment] = useState(null);
  const [invoiceId,] = useState(props.Invoice.id);
  const [paymentText, setPaymentText] = useState("");
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { user } = useContext(AuthContext);
  const [qrCodeData, setQrCodeData] = useState({
    show: false,
    image: '',
    payload: '',
  })

  function _renderStepContent(step, setFieldValue, setActiveStep, values) {

    switch (step) {
      case 0:
        return <AddressForm formField={formField} values={values} setFieldValue={setFieldValue} />;
      case 1:
        return <PaymentForm
          formField={formField}
          setFieldValue={setFieldValue}
          setActiveStep={setActiveStep}
          activeStep={step}
          invoiceId={invoiceId}
          values={values}
        />;
      case 2:
        return <ReviewOrder />;
      default:
        return <div>Not Found</div>;
    }
  }


  async function _submitForm(values, actions) {
    try {
      const plan = JSON.parse(values.plan);
      const newValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country,
        useAddressForPaymentDetails: values.useAddressForPaymentDetails,
        nameOnCard: values.nameOnCard,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        plan: values.plan,
        price: plan.price,
        users: plan.users,
        connections: plan.connections,
        invoiceId: invoiceId
      }

      const { data } = await api.post("/subscription", newValues);
      setDatePayment(data);
      setPaymentText("Pagamento pendente.");
      actions.setSubmitting(true);

      setQrCodeData({
        show: true,
        image: data.data.encodedImage,
        payload: data.data.payload
      });

      const checkStatus = setInterval(async () => {
        const paymentStatus = await api.get(`/subscription/status/${data.paymentId}/${newValues.invoiceId}`)

        if (paymentStatus.data.paymentStatus.status === 'RECEIVED') {
          setPaymentText('Pagamento confirmado!');

          clearInterval(checkStatus);

          setTimeout(() => {
            window.location.reload();
          }, 10000)
        }
      }, 1000)

      toast.success("Assinatura realizada com sucesso!, aguardando a realização do pagamento");
    } catch (err) {
      actions.setSubmitting(false);
      toastError(err);
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Falta pouco!
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess pix={datePayment} />
        ) : (
          <Formik
            initialValues={{
              ...user,
              ...formInitialValues
            }}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form id={formId} style={{ minWidth: "500px" }}>
                {_renderStepContent(activeStep, setFieldValue, setActiveStep, values)}
                {qrCodeData.show && (
                  <Container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: 'column',
                      marginTop: 20,
                    }}
                  >
                    {paymentText && (
                      paymentText === 'Pagamento pendente.' ? (
                        <div style={{ padding: '10px', borderRadius: '8px', marginTop: '10px' }}>
                          <Typography variant="h5" align="center" style={{ color: '#ee7a21', fontWeight: 'bold' }}>
                            {paymentText}
                          </Typography>
                        </div>
                      ) : (
                        <div style={{ padding: '10px', borderRadius: '8px', marginTop: '10px' }}>
                          <Typography variant="h5" align="center" style={{ color: '#27C100', fontWeight: 'bold' }}>
                            {paymentText}
                          </Typography>
                        </div>
                      )
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <QRCode
                        value={qrCodeData.payload}
                        size={256}
                        level="H"
                        includeMargin={true}
                      />
                    </div>
                    <Typography style={{ margin: '20px 0px', backgroundColor: '#efefef', borderRadius: '4px', textAlign: 'center', padding: '4px' }}>
                      {qrCodeData.payload}
                    </Typography>
                  </Container>
                )}

                <div className={classes.buttons}>
                  {activeStep !== 1 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      VOLTAR
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    {activeStep !== 1 && (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? "PAGAR" : "PRÓXIMO"}
                      </Button>
                    )}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
