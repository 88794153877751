import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { versionSystem } from "../../../package.json";
import { nomeEmpresa } from "../../../package.json";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logologin.png";
import background from "../../assets/background-login.jpg";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="#">
        {nomeEmpresa} - v {versionSystem}
      </Link>
      {"."}
    </Typography>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  paper: {
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "6px solid transparent", // Adiciona uma borda transparente
    boxShadow: "0 0 180px rgba(0, 0, 0, 0.3)", // Adiciona um efeito de sombra azul
    maxWidth: "480px",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: "20px",
    padding: "10px 20px",
    fontSize: "1.2em",
  },
  logo: {
    width: "600px",
    height: "600px",
    border: 0,
    marginTop: "96px",
  },
  passwordIcon: {
    cursor: "pointer",
  },
  whatsappButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#25d366",
    "&:hover": {
      backgroundColor: "#128c7e",
    },
  },
  whatsappIcon: {
    fontSize: 40,
    color: "#fff",
  },
  formContainer: {
    background: `url(${background}) center/cover no-repeat`,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.container}>
      <Container item style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', height: "100%" }}>
        <iframe src="https://lottie.host/embed/83eb2797-50e4-4d02-8f96-16828ece15ee/TBpICzmcll.json" className={classes.logo}></iframe>
        <Box mt={12}><Copyright /></Box>
      </Container>
      <Container item className={classes.formContainer}>
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} style={{ marginBottom: "36px", width: "360px" }} />
          <Typography component="h1" variant="h5" style={{ marginBottom: "12px" }}>
            Faça login na sua conta
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={user.email}
              onChange={handleChangeInput}
              style={{
                backgroundColor: "#e8f0fe"
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={user.password}
              onChange={handleChangeInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.passwordIcon}
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{
                backgroundColor: "#e8f0fe"
              }}
            />
            <Grid container justify="flex-end">
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Link component={RouterLink} to="/forgetpsw" variant="body2">
                  Esqueceu sua senha?
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ borderRadius: "10px", padding: "5px 12px", fontSize: "1em", marginTop: "20px", height: "40px", color: '#fff' }}
            >
              Entrar
            </Button>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Grid item>
                <Link component={RouterLink} to="/signup" variant="body2">
                  {"Não tem uma conta? Registre-se"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <IconButton
        href={`https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`}
        className={classes.whatsappButton}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon className={classes.whatsappIcon} />
      </IconButton>
    </div>
  );
};

export default Login;
