import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const HubChannelModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [channels, setChannels] = useState([]);
	console.log('channels', channels);
  const [selectedChannels, setSelectedChannels] = useState([]);

  useEffect(() => {
    if (!open) return;

    (async () => {
      try {
        const { data } = await api.get(`/hub-channel`);
        console.log(data);
        setChannels(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [open]);

  const handleSaveChannels = async () => {
    try {
      console.log(selectedChannels);
      const data = selectedChannels.map((channel) => ({
        token: channel.id,
        name: channel.name,
        channel: channel.channel,
      }));

      await api.post(`/hub-channel`, {
        channels: data,
      });

      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    setSelectedChannels([]);
    onClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle>Adicionar canais</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {channels.length > 0 && (
                <>
                  <InputLabel id="demo-simple-select-label">
                    Selecionar canais
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedChannels}
                    renderValue={(selected) =>
                      selected.map((channel) => channel.name).join(", ")
                    }
                    onChange={(e) => setSelectedChannels(e.target.value)}
                    fullWidth
                    multiple
                    variant="outlined"
                  >
                    {channels.map((channel) => (
                      <MenuItem key={channel.id} value={channel}>
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                              backgroundColor: "#ccc",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {['whatsapp_business_account', 'whatsapp'].includes(channel.channel) ? <WhatsAppIcon /> : channel.channel === 'telegram' ? <TelegramIcon /> : <FacebookIcon />}
                          </div>
                        {channel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}

              {channels.length === 0 && (
                <p>
                  Nenhum canal disponível. Para cadastrar seus canais no Hub{" "}
                  <a
                    href="https://hub.notificame.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    clique aqui
                  </a>
                  .
                </p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="button"
            disabled={selectedChannels.length === 0}
            color="primary"
            onClick={handleSaveChannels}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(HubChannelModal);
