import React, { useContext, useState } from "react";
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress,
    Grid,
    TextField,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },

    textCenter: {
        backgroundColor: 'red'
    }
}));

const onlyNumbers = (value) => value.replace(/[^\d]/g, '');

const DocumentSchema = Yup.object().shape({
    document: Yup.string()
        .required('CPF/CNPJ é obrigatório')
        .test('len', 'CPF ou CNPJ inválido', (value) => {
            const onlyDigits = onlyNumbers(value);
            return (
                (onlyDigits.length === 11 && /^\d{11}$/.test(onlyDigits)) ||
                (onlyDigits.length === 14 && /^\d{14}$/.test(onlyDigits))
            );
        })
        .matches(
            /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$|^[0-9]{11}$|^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
            'CPF ou CNPJ inválido.'
        ),
});

export default function DocumentForm(companyId) {
    const classes = useStyles();
    const theme = useTheme();

    const id = companyId.companyId;

    const handleSaveDocument = async values => {
        const companyData = { id: id, ...values };

        try {
            await api.put(`/companies/${id}`, companyData);

            toast.success(i18n.t("documentModal.success"));
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
                {i18n.t('documentModal.title')}
            </Typography>

            <Typography align="center" style={{ margin: "14px 0px" }}>
                {i18n.t('documentModal.description')}
            </Typography>

            <React.Fragment>
                <Formik
                    initialValues={{ document: '' }}
                    validationSchema={DocumentSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveDocument(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={"CPF/CNPJ"}
                                        name="document"
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                    {touched.document && errors.document && (
                                        <div>{errors.document}</div>
                                    )}
                                </Grid>
                                <Grid item xs={12} spacing={1}>
                                    <Grid container spacing={1}>
                                        <Grid classes={classes.textCenter} xs={12} item>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                disabled={isSubmitting}
                                                variant="contained"
                                                className={classes.btnWrapper}
                                                style={{ color: theme.palette.buttonTextColor }}
                                                fullWidth
                                            >

                                                {i18n.t("documentModal.add")}
                                                {isSubmitting && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </React.Fragment>
    );
}
